import { useAppDispatch } from '../../app/hooks';
import { ReactNode, useEffect, useState } from 'react';
import { logout, m2mAuthorizeClear, m2mAuthorizeTrigger, notificationPostTrigger } from '../../app/api/actions';
import { useSelector } from 'react-redux';
import { selectNotificationPost } from '../../app-view/api-view';
import Message from '../organism/Message';
import FormEmail from '../organism/FormEmail';
import Loader from '../organism/Loader';
import { selectorAuthUser, selectorTokenPresent } from '../../app/api/selector';
import { Button, makeStyles } from '@material-ui/core';
import { authOtpSetup, authOtpStartup } from '../../app/auth-otp/actions';
import { selectorAuthOtpError, selectorAuthOtpStatus } from '../../app/auth-otp/selector';
import { authWebSetup, authWebStartup } from '../../app/auth-web/actions';
import { selectorAuthWebResponse, selectorAuthWebStatus } from '../../app/auth-web/selector';
import AuthWeb from './AuthWeb';
import FormEmailService from '../organism/FormEmailService';
import { NotificationPayload } from '../../app/api/types';


const useStyles = makeStyles((theme) => ({
    itemHalf: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        width: "35%",
    },
  }));
interface Prop {
    children?: ReactNode,
}
function VoiceLogins({children} : Prop) {
    const dispatch = useAppDispatch()
    const tokenPresent = useSelector(selectorTokenPresent)
    const handleVoiceLogin = (request: NotificationPayload & { submitButton: string}) => {
        if(request.submitButton == "OTP") {
            dispatch(authOtpSetup({email: request.email!,...request}))
        } else if(request.submitButton == "WEB") {
            dispatch(authWebSetup({email: request.email!,...request}))
        }
    }
    const statusOtp = useSelector(selectorAuthOtpStatus)
    const statusWeb = useSelector(selectorAuthWebStatus)
    const responseWeb = useSelector(selectorAuthWebResponse)
    const errorOtp = useSelector(selectorAuthOtpError)
    const reset = () => {
        dispatch(authOtpStartup())
        dispatch(authWebStartup())
    }
    const logoutAndReset = () => {
        dispatch(logout())
        reset()
    }
    useEffect(() => {
        reset()
    }, [dispatch])
    const user = useSelector(selectorAuthUser)
    const style = useStyles()
    if(tokenPresent) {
        return (
            <div>
                <p>Hi {user.first_name || user.email}</p>
                <Button onClick={logoutAndReset}>Logout</Button>
            </div>
        )
    }
    if(statusOtp == 'loading-phrase' || statusWeb == 'loading-phrase') {
        return <Loader></Loader>
    }
    if(statusOtp == 'request-user' && statusWeb != 'request-user') {
        const retryWeb = () => {
            if(responseWeb.email !== undefined && responseWeb.max_attempts && responseWeb.max_attempts > 0)
                dispatch(authWebSetup({email: responseWeb.email, session_id: responseWeb.session_id}))
            else reset()
        }
        return <AuthWeb okComplete={reset} okOnError={retryWeb}></AuthWeb>
    }
    if(statusOtp == 'auth-error-auth') {
        if (errorOtp)
            return (<Message title={`${errorOtp.code}: ${errorOtp.detail}`} onClick={reset}/>);
        else
            return (<Message title={`Uknown error, login failed.`} onClick={reset}/>);
    }
    return <FormEmailService hideSubmit={true} onClick={handleVoiceLogin}>
            <Button className={style.itemHalf} variant='outlined' type="submit">OTP</Button>
            <Button className={style.itemHalf} variant='outlined' type="submit">WEB</Button>
            {children}
        </FormEmailService>
}
export default VoiceLogins;
