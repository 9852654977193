import { selectorAuthWebState } from '../../../app-view/auth-web';
import { useDispatch, useSelector } from 'react-redux';
import UserSelection from './UserSelection';
import LoginWeb from './LoginWeb';
import voicemeLoading from '../../../assets/images/voiceme-loading.svg'
import Loader from '../../organism/Loader';
import Message from '../../organism/Message';
import { Navigate, useNavigate } from 'react-router';
import { authWebSetup, authWebStartup } from '../../../app/auth-web/actions';
import FormEmailService from '../../organism/FormEmailService';
import { AuthGetRequest, NotificationPayload } from '../../../app/api/types';
import { useAppDispatch } from '../../../app/hooks';

interface Prop {
    okComplete: () => void
    okOnError: () => void
}

function AuthWeb({okComplete, okOnError }: Prop) {
    const dispatch = useAppDispatch()
    const flow = useSelector(selectorAuthWebState)

    const handleVoiceOTP = (setup: NotificationPayload) => {
        dispatch(authWebSetup({email: setup.email!, ...setup}))
    }
    switch(flow.status) {
        case 'request-user':
            return (<FormEmailService onClick={handleVoiceOTP}></FormEmailService>);
        case 'show-video':
            return (<LoginWeb></LoginWeb>);
        case 'loading-phrase':
            return (<Loader/>);
        case 'complete':
            return (<Message severity='success' title='Login completed' onClick={okComplete}/>);
        case 'auth-error-sentence':
            return (<Message title={`Error during connection with the server: ${flow.error ?? 'timeout'}`} onClick={okOnError}/>);
        case 'auth-error-auth':
            return (<Message title={`Authorization failed: ${flow.error ?? 'timeout'}`} onClick={okOnError}/>);
    }
}

export default AuthWeb;
