

import { Action } from "redux";
import { Observable, Subject, merge, of } from "rxjs";
import { delay, filter,  map,  mergeMap,  switchMap, takeUntil, tap, withLatestFrom } from "rxjs/operators";
import { RootState } from "../store";
import { apiToken, authorizeGetFailed, authorizeGetProcessed, authorizeGetTrigger, authorizePostFailed, authorizePostProcessed, authorizePostTrigger } from "../api/actions";
import { authWebComplete, authWebErrorAuth, authWebErrorSentence, authWebRecord, authWebRequest, authWebSetup } from "./actions";
 

export const epicAuthWebSetup = (action$: Observable<Action>, state$: Observable<RootState>) => {
  const waitWebSetupInit$ = action$.pipe(
    filter(authWebSetup.match),
    withLatestFrom(state$),
    filter(([action, state]) => state.api.api == undefined),
    delay(200),
    map(([action, s]) => action)
  );  
  const webSetupTrigger$ = action$.pipe(
    filter(authWebSetup.match),
    withLatestFrom(state$),
    filter(([action, state]) => state.api.api != undefined),
    switchMap(([action, state]) => {
          const complete = new Subject()
          return merge(
            of(authorizeGetTrigger(action.payload)),
            action$.pipe(
                filter(authorizeGetProcessed.match),
                takeUntil(complete),
                tap(() => complete.complete()),
                map((a) => authWebRecord(a.payload)),
            ),
            action$.pipe(
                filter(authorizeGetFailed.match),
                takeUntil(complete),
                tap(() => complete.complete()),
                map((a) => authWebErrorSentence(a.payload)),
            )
        )
    })
  );
  return merge(waitWebSetupInit$,webSetupTrigger$)
}
    
export const epicAuthWebRequest = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
      filter(authWebRequest.match),
      switchMap((action) => {
            const complete = new Subject()
            return merge(
              of(authorizePostTrigger(action.payload)),
              action$.pipe(
                  filter(authorizePostProcessed.match),
                  takeUntil(complete),
                  tap(() => complete.complete()),
                  mergeMap((action) => of(
                    apiToken(action.payload),
                    authWebComplete(action.payload),
                  )
              )),
              action$.pipe(
                  filter(authorizePostFailed.match),
                  takeUntil(complete),
                  tap(() => complete.complete()),
                  map((a) => authWebErrorAuth(a.payload))
              ),
          )
      })
    );