export declare interface CallApiPayload {
    reject(arg0: any): any
    success(arg0: any): any
    api: string
    path: string
    method: "POST" | "GET" | "PATCH" | "DELETE"
    body?: any
    auth: boolean
    ignoreResponse?: boolean
}

export declare interface CallApiErrorResponse {
    code: string
    detail: string
}

export type GenericError = {
    code: string
    detail: string
}

export declare interface SessionPayload {
    session_id: string
}

export declare interface AudioPayload {
    video: {
        type: string,
        dataURL: string
    },
}
export declare interface VideoPayload {
    audio: {
        type: string,
        dataURL: string
    },
}

export declare interface AuthorizePayload extends AudioPayload, VideoPayload, SessionPayload {
    email: string
    mobile?: string
    session_id: string
}

export declare interface EnrollPayload extends AudioPayload, VideoPayload, SessionPayload {
    pin_number: string
}

export declare interface InitApiPayload {
    confirm: string
    document: string
    endpoint?: string
    enroll: string
    authorize: string
    m2mAuthorize: string
    notifications: string
    oidc: string
    partner: string
    partnerSignup: string
    profilePayment: string
    signup: string
}

export declare interface ConfigEnvironment {
    name: string
    url: string
    ui_url: string
    default_service: string
}
export declare interface VoicemeToken {
    access_token:  string,
    refresh_token: string,
    user?: {
        pk: number,
        email: string,
        first_name: string,
        last_name: string
    },
    access_token_expiration?:  string
    refresh_token_expiration?: string
}

export interface APIData<T1 = undefined, T2 = undefined> {
    request: T1,
    response: T2,
    error?: GenericError,
}

export const APIDataInit = () => ({
    request: undefined,
    response: undefined,
    error: undefined,
})

export declare interface AddressPayload { 
    line1: string,
    line2: string,
    postal_code: string,
    city: string,
    state: string,
    country: string,
}

// API Payload
export declare interface SignupPayload {
    first_name: string
    last_name: string
    email?: string
    mobile?: string
    customer?: string
}

export declare interface SignupPartnerPostPayload {
    partner: {
        name: string,
        is_private?: boolean,
        full_name_referent?: string,
        role_referent?: string,
        mobile_referent?: string,
        email?: string,
        pi?: string,
        tax_id?: string,
        registered_office?: AddressPayload,
        billing_address?: AddressPayload,
    },
    otp_email?: string,
}

export declare interface ConfirmPayload {
    email?: string,
    otp_email?: string,
    mobile?: string,
    otp_mobile?: string,
}

export declare interface SignupPartnerGetPayload {
    email: string
}

export declare interface ConfigureGetPayload {
    config: string
}

export declare interface ConfigureGetResponse {
    environments: ConfigEnvironment[]
}

export declare interface ProfilePayment {
    return_url: string
}

export declare interface ProfilePaymentResponse {
    url: string
}


export declare interface AddressType {
    city: string,
    country: string,
    line1: string,
    line2: string,
    postal_code: string,
    state: string,
}

export declare interface ProfilePaymentGetResponse {
    has_payment: boolean
    address: AddressType
}

export declare interface PartnerList {
    results: {
        billing_status: ProfilePaymentGetResponse
    }[]
}


export declare interface M2MAuthorize {
    key: string,
    secret: string,
}

export declare interface NotificationPayload {
    email?: string
    mobile?: string
    custom_body?: string
    service_id: string
}

export declare interface NotificationDescriptor {
    session_id: string
    secret?: string
}

export declare interface NotificationResponse extends NotificationDescriptor {
    status: string
    token?: VoicemeToken
}

export declare interface ArrayResponse<T> {
    count: number
    next: string
    previous: string
    results: T[]
}

export declare interface DocumentRequest {
    id?: string
    sign?: boolean
    document?: string
    fileName?: string
    service_id?: string
}

export declare interface DocumentResponse {
    id: string
    file_name: string
    document_base64: string
    document_signed_base64: string
    session_id: string
    secret: string
}


export declare interface OIDCPayload {
    client_id: string
    code_challenge_method?: 'S256' | 'plain'
    code_challenge?: string
    email?: string
    next?: string
    nonce: string
    redirect_uri: string
    response_mode: 'query' | 'form_post' | 'fragment'
    response_type: 'token' | 'code' | 'id_token' | 'code token' | 'code id_token' | 'token code id_token'
    scope: string
    state: string
}

export declare interface OIDCVoiceOtpPayload extends OIDCPayload, NotificationPayload {
}


export declare interface AuthGetRequest {
    email: string
    session_id?: string
    service_id?: string
}

export declare interface AuthGetResponse {
    max_attempts: number
    media_length: number
    sentence: string
    session_id: string
}