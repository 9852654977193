import { createSlice } from '@reduxjs/toolkit';
import { authWebErrorAuth, authWebErrorSentence, authWebRecord, authWebRequest, authWebSetup, authWebStartup } from './actions';
import { AuthWebStore } from './types';
import { apiToken } from '../api/actions';

const initialState: AuthWebStore = {
    status: "request-user"
};

export const authWeb = createSlice({
  name: 'auth-web',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
    .addCase(authWebStartup, (state, action) => {
      state.authGetResponse = undefined
      state.email = undefined
      state.service_id = undefined
      state.error = undefined
      state.status = "request-user"
    })
    .addCase(authWebSetup, (state, action) => {
      state.authGetResponse = undefined
      state.email = action.payload.email
      state.service_id = action.payload.service_id
      state.error = undefined
      state.status = "loading-phrase"
    })
    .addCase(authWebRequest, (state, action) => {
      state.status = "loading-phrase"
    })
    .addCase(authWebRecord, (state, action) => {
      state.status = "show-video"
      state.authGetResponse = action.payload
    })
    .addCase(apiToken, (state, action) => {
      state.status = "complete"
    })
    .addCase(authWebErrorAuth, (state, action) => {
      state.status = "auth-error-sentence"
      state.error = action.payload
    })
    .addCase(authWebErrorSentence, (state, action) => {
      state.status = "auth-error-auth"
      state.error = action.payload
    })
  },
});

export default authWeb.reducer;
