import React, { ReactNode } from 'react';
import { Typography, Button, TextField } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import { useAppDispatch } from '../../../app/hooks';
import { enroll } from '../../../app/enroll/reducer';
import { enrollGetTrigger } from '../../../app/api/actions';
import { AuthGetRequest } from '../../../app/api/types';
import { useSelector } from 'react-redux';
import { selectorEmail } from '../../../app-view/enroll-view';

interface Prop {
    onStartBiometricEnrollment?: () => void;  // Callback for biometric profile action
    children?: ReactNode;
}

const useStyles = makeStyles((theme) => ({
    pageWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: theme.palette.background.default,
    },
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(4),
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[3],
        maxWidth: '400px',
        width: '100%',
    },
    question: {
        marginBottom: theme.spacing(2),
        color: theme.palette.text.primary,
        textAlign: 'center',
    },
    form: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(2),
        width: '100%',
    },
    item: {
        marginTop: theme.spacing(2),
        width: "100%",
        "& input:-webkit-autofill": {
            color: "white !important",
            "-webkit-text-fill-color": "white !important",
        },
        "& input::spelling-error": {
            textDecoration: "underline dotted red !important", // Sottolinea gli errori ortografici
            color: "white !important", // Colore del testo
        },
        "& input::grammar-error": {
            textDecoration: "underline wavy blue !important", // Sottolinea gli errori grammaticali
            color: "white !important", // Colore del testo
        },
    },
}));

function BiometricEnrollmentForm({ onStartBiometricEnrollment, children }: Prop) {
    const style = useStyles();
    const dispatch = useAppDispatch();
    const emailSelector = useSelector(selectorEmail);

    const handleStartBiometricEnrollment = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const serviceId = formData.get('service') as string;
        const payload: AuthGetRequest = {
            email: emailSelector.email!,
            session_id: undefined,
            service_id: serviceId,
        };
        dispatch(enrollGetTrigger(payload));
        onStartBiometricEnrollment?.();
    };

    return (
        <div className={style.pageWrapper}>
            <div className={style.formContainer}>
                <Typography variant="h5" className={style.question}>
                    Would you like to enroll a new biometric profile?
                </Typography>
                <form className={style.form} onSubmit={handleStartBiometricEnrollment}>
                    <TextField  
                        className={style.item} 
                        id="service" 
                        name="service"
                        label="Please enter the services to be used" 
                        variant="outlined" 
                        type="text" 
                        color='primary' 
                        defaultValue="VOICEME" 
                        fullWidth
                    />
                    <Button
                        className={style.button}
                        variant="contained"
                        color="primary"
                        type="submit"
                    >
                        Start New Biometric Profile
                    </Button>
                </form>
                {children}
            </div>
        </div>
    );
}

export default BiometricEnrollmentForm;
